import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import HeroImage from "../images/mini-hd-walk-behind-header.jpg"
import styled from "styled-components"
import SeeMoreButton from "../components/controls/SeeMoreButton"
import ProductLayout from "../components/ProductLayout"
import { Link } from "gatsby"

import PilotImage from "../images/pilot-29C-1.jpg"
import GTXImage from "../images/gtx-33c-1.jpg"
import XRImage from "../images/xr-46c-1.jpg"
import GTRImage from "../images/gtr-37C-1.jpg"

const WbfsPageStyles = styled.div`
  .hero-container {
    position: relative;

    width: 100%;
    height: 30vh;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .heading {
    position: absolute;
    top: 50%;
    margin-top: -170px;
    width: 100%;
    /* background: rgba(0, 0, 0, 0.7); */
    padding: 3rem 0;
    font-size: 2em;
    font-weight: bold;
    color: white;

    text-align: center;
    font-family: "Titillium Web", sans-serif;
    @media (max-width: 840px) {
      margin-top: -250px;
    }

    h1 {
      margin: 0;
    }
  }

  .product-layout {
    /* margin-top: 150px; */
    padding: 2rem;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 1rem;
    justify-items: center;

    @media (max-width: 1420px) {
      width: 95%;
      /* margin: 0; */
      grid-gap: 0;
    }
    @media (max-width: 900px) {
      padding: 1rem;
      grid-gap: 0;
    }
    @media (max-width: 700px) {
      padding: 0.5rem;
      grid-gap: 0;
      grid-template-columns: 100%;
    }
  }

  button {
    background-color: orange;
    text-align: center;
    height: 40px;
    padding: 0.5rem;
    border: none;
    color: white;
    margin: 3rem 0;
    /* margin: 4rem 0 0 12rem; */
    /* border-radius: 5px; */
  }
`

const RideOnFloorScrubbers = () => {
  return (
    <>
      <SEO title="Walk Behind Floor Scrubbers" />
      <Layout>
        <WbfsPageStyles>
          <div
            style={{
              backgroundColor: "black",
              width: "100%",
              height: "30px",
            }}
          ></div>
          <div className="hero-container">
            <img src={HeroImage} alt="" />
          </div>
          {/* <div className="heading">
            <h1>Ride On Floor Scrubbers</h1>
            <p style={{ margin: "0", fontSize: ".6em", padding: ".5rem" }}>
              Providing outstanding quality and durability to last years.
            </p>
            <SeeMoreButton
              title="See Machines"
              goTo="#walk-behind-floor-scrubber-products"
            />
          </div> */}
          <div
            style={{
              backgroundColor: "black",
              width: "100%",
              height: "30px",
            }}
          ></div>

          <div
            className="product-layout"
            id="walk-behind-floor-scrubber-products"
          >
            {/* <Link
              style={{ textDecoration: "none", color: "black" }}
              to="/ride-on-floor-scrubbers/pilot"
            > */}
            <ProductLayout
              image={PilotImage}
              title="Pilot v2.1"
              linkName="Download Pilot v2.1 Brochure"
              link="https://www.dropbox.com/s/u77dwgm4t3xffk6/FC-V2-RIDERS-SM.pdf?dl=0"
              content="Ultra-maneuverable, this micro rider affords you the efficient cleaning of a ride-on auto scrubber with a footprint smaller than many large walk-behind auto scrubbers.  Able to navigate tight isles, narrow doorways, and smaller elevators this ride-on auto scrubber is best in class. Available in 25”(64cm) - 28”(71cm) scrub width."
              navigate="/ride-on-floor-scrubbers/pilot"
              imageLink="/ride-on-floor-scrubbers/pilot"
              subTitle="Battery Micro Ride-On Floor Scrubber"
            />
            {/* </Link> */}
            {/* <Link
              style={{ textDecoration: "none", color: "black" }}
              to="/ride-on-floor-scrubbers/gtx"
            > */}
            <ProductLayout
              image={GTXImage}
              title="GTX v2.1"
              linkName="Download GTX v2.1 Brochure"
              link="https://www.dropbox.com/s/u77dwgm4t3xffk6/FC-V2-RIDERS-SM.pdf?dl=0"
              content="Navigation of your facility is easy with this compact ride on auto scrubber.  Say, “so long” to the fatigued operator from walking behind a traditional auto scrubber. With this ride on auto scrubber your staff will clean more square footage during their shift and still have the time and energy to do additional work.  With impressive down pressure and brush speed this machine cleans your toughest dirt. Available in 26”(68cm) - 33”(84cm) scrub width.  "
              navigate="/ride-on-floor-scrubbers/gtx"
              imageLink="/ride-on-floor-scrubbers/gtx"
              subTitle="Battery Compact Ride-On Floor Scrubber"
            />
            {/* </Link> */}
            {/* <Link
              style={{ textDecoration: "none", color: "black" }}
              to="/ride-on-floor-scrubbers/gtr"
            > */}
            <ProductLayout
              image={GTRImage}
              title="GTR v2.1"
              linkName="Download GTR v2.1 Brochure"
              link="https://www.dropbox.com/s/u77dwgm4t3xffk6/FC-V2-RIDERS-SM.pdf?dl=0"
              content="Handle your toughest cleaning jobs with our midsized battery powered ride on auto scrubber. Filthy and grimy floors can be cleaned and restored with ease with this rugged floor scrubber. High speed scrubbing brushes and generous down pressure makes cleaning your floors easy covering a great deal of square footage per hour.  Available in 26”(68cm) - 33”(84cm) scrub width."
              navigate="/ride-on-floor-scrubbers/gtr"
              imageLink="/ride-on-floor-scrubbers/gtr"
              subTitle="Battery Ride-On Floor Scrubber"
            />
            {/* </Link> */}
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="/ride-on-floor-scrubbers/xr"
            >
              <ProductLayout
                image={XRImage}
                title="XR v2.0"
                linkName="Download XR v2.0 Brochure"
                link="https://www.dropbox.com/s/41xjmutn9ud9afx/FC-XR-BROCHURE-SM.pdf?dl=0"
                content="Clean your large space easily and quickly with great maneuverability? Our XR ride on floor scrubber is industrial grade throughout and great for warehouses, distribution centers or any other large square footage buildings.  It scrubs filthy, dirty floors better than any other floor scrubber in its class.  The XR offers the widest scrub path of all the machines we make and comes standard with dual vacuums leaving your floors exceptionally dry. Available in 34”(68cm) - 46”(84cm) scrub width."
                navigate="/ride-on-floor-scrubbers/xr"
                imageLink="/ride-on-floor-scrubbers/xr"
                subTitle="Battery Ride-On Floor Scrubber"
              />
            </Link>

            {/* <button>Download FactoryCat's Full Line Brochure</button> */}
            {/* <ProductLayout />
            <ProductLayout />
            <ProductLayout />
            <ProductLayout />
            <ProductLayout /> */}
          </div>
        </WbfsPageStyles>
      </Layout>
    </>
  )
}

export default RideOnFloorScrubbers
